import type { IRetrieveOptions, ItemModel, DataItemModel, RecordSourceOptions } from 'o365-dataobject';
import type { INodeDataLevelConfiguration, NodeDataStructureOptions, NewNodeOptions } from './DataObject.NodeData.ts';

import { DataObject, Item as DataItem } from 'o365-dataobject';
import { $t, logger, BulkOperation } from 'o365-utils';

import { NodeItem, getNodeItemModel } from './DataObject.NodeItem.ts';
import { sortData } from './DataObject.utils.ts';
import { userSession } from 'o365-modules';

export class HierarchyLevelConfiguration<T extends ItemModel = ItemModel> implements INodeDataLevelConfiguration<T> {
    private _dataObject: DataObject<T>;
    private _parentField?: keyof T & string;
    private _idField?: keyof T & string;
    private _idPathField?: keyof T & string;
    private _bindToParent?: NodeDataHierarchyConfigurationOptions<T>['bindToParent'];
    private _parseKeyArray: (pItem: Partial<T>) => string[];
    private _getSummaryItem?: NodeDataHierarchyConfigurationOptions<T>['getSummaryItem'];
    private _aggregateSummaryNodes?: NodeDataHierarchyConfigurationOptions<T>['aggregateSummaryNodes'];
    private _additionalFields?: string[];
    private _requireParents: boolean;
    private _key: string;
    private _disabled = false;

    private _canCreateNodes?: (pNode: NodeItem<T>) => boolean;

    private _structureItems: Map<string, Record<string, any>> = new Map();

    private _retrieveByKey: BulkOperation<number | string, T[]>;

    level = 0;

    expandByDefault = false;

    get ui() {
        return {
            title: this._idPathField ? $t(this._idPathField) : `${$t(this._parentField ?? '')}/${$t(this._idField ?? '')}`,
            type: $t('Hierarchy')
        };
    }
    get key() {
        return this._key;
    }

    get keyField() {
        return this._idField ?? this._dataObject.fields.uniqueField ?? 'PrimKey';
        // return 'PrimKey';
    }
    get idField() { return this._idField; }
    get parentField() { return this._parentField; }
    get idPathField() { return this._idPathField; }

    get disabled() { return this._disabled; }
    set disabled(pValue) { this._disabled = pValue; }

    readonly type = 'hierarchy';

    get requireParents() { return this._requireParents; }

    getConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined;

    constructor(pDataObject: DataObject<T>, pOptions: NodeDataHierarchyConfigurationOptions<T>, pGetConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined) {
        if (pOptions.idPathField == null && (pOptions.parentField == null || pOptions.idField == null)) {
            throw new TypeError('Either idPathField or parentField with idField must be provided ');
        }
        this._dataObject = pDataObject;

        this.getConfigurationForLevel = pGetConfigurationForLevel;

        this._idField = pOptions.idField;
        this._parentField = pOptions.parentField;
        this._idPathField = pOptions.idPathField;
        this._additionalFields = pOptions.additionalFields;
        this._getSummaryItem = pOptions.getSummaryItem;
        this._aggregateSummaryNodes = pOptions.aggregateSummaryNodes;

        this._canCreateNodes = pOptions.canCreateNodes;
        this._requireParents = pOptions.requireParents ?? false;
        if (pOptions.parseKeyArray) {
            this._parseKeyArray = pOptions.parseKeyArray;
        } else {
            this._parseKeyArray = (pItem) => {
                const idPath = pItem[this._idPathField!];
                const keyArray = idPath.split('/');
                keyArray.splice(0, 1);
                keyArray.splice(keyArray.length - 1, 1);
                return keyArray;
            };
        }
        this._key = window.crypto.randomUUID();

        this._retrieveByKey = new BulkOperation<number | string, T[]>({
            bulkOperation: async (pItems) => {
                const data = await this._dataObject.recordSource.bulkRetrieve(pItems.map(x => x.value), this.keyField);
                pItems.forEach(item => {
                    const rows = data.filter(x => x[this.keyField] === item.value);
                    item.res(rows);
                });
            },
            bulkSize: 500
        });
    }

    async refreshItemByKeyField(pKey: string | number) {
        const record = this._dataObject.storage.getItemByField(this.keyField, pKey as any);
        if (record == null) {
            const data = await this._retrieveByKey.addToQueue(pKey);
            if (data == null) {
                throw new Error(`Something went wrong when retrieving values for item with [${this.keyField}] = '${pKey}' binding. Didn't return any data`)
            } else if (data.length > 1) {
                throw new Error(`Something went wrong when retrieving values for item with [${this.keyField}] = '${pKey}' binding. Returned more than 1 row`);
            }
            const newRecord = this._dataObject.createNew(data?.[0], false, false, true);
            newRecord.state.isNewRecord = false;
            newRecord.reset();
            return newRecord
        } else {
            return record;
        }
    }

    /** 'Server side' (will do a retreive for all ParentId/ID and construct the paths on client ) */
    async getStructure(pOptions: NodeDataStructureOptions, pNode?: NodeItem<T>) {
        const options: RecordSourceOptions & Partial<IRetrieveOptions> = this._dataObject.recordSource.getOptions();
        if (this._dataObject.clientSideFiltering) {
            this._dataObject.storage.clearItems()
        }
        if (this._dataObject.recordSource.prevWhereClauseSingle !== this._dataObject.recordSource.whereClause) {
            this._structureItems.clear();
        }
        this._dataObject.recordSource.updatePreviousWhereClause();
        options.whereClause = this._dataObject.recordSource.whereClause;
        const contextFilter = this._dataObject.recordSource.getContextFilterString();
        if (contextFilter) {
            if (this._requireParents) {
                if (options.filterString) {
                    options.filterString += ` AND ${contextFilter}`;
                } else {
                    options.filterString = contextFilter;
                }
            } else {
                if (options.whereClause) {
                    options.whereClause += ` AND ${contextFilter}`;
                } else {
                    options.whereClause = contextFilter;
                }
            }
        }

        const parentLevel = (pNode?.level ?? -1) + 1;
        const parentFilterString = pNode?.getFilterString ? pNode.getFilterString() : undefined;
        if (parentFilterString) {
            if (options.whereClause) {
                options.whereClause = `${options.whereClause} AND ${parentFilterString}`;
            } else {
                options.whereClause = parentFilterString;
            }
        }

        // options.skipClientSideHandlerCheck = true;
        options.maxRecords = -1;
        options.skip = 0;
        options.loadRecents = undefined;
        if (this._structureItems.size === 0 && this._requireParents) {
            options.filterString = undefined;
        }
        options.fields = [
            { name: this._dataObject.fields.uniqueField || 'PrimKey' }
        ];
        if (this._idPathField) {
            options.fields.push({ name: this._idPathField });
        } else if (this._idField && this._parentField) {
            options.fields.push({ name: this._idField });
            options.fields.push({ name: this._parentField });
        } else {
            throw new TypeError('Invalid configuration for tree structure retrieval');
        }

        // TODO(Augustas): Make sure even half configured sort by is included ( sortOrder without sortDirection)
        this._dataObject.recordSource.getSortOrder().forEach((sort, index) => {
            Object.entries(sort).forEach(([key, direction]) => {
                const field = options.fields!.find(x => x.name === key);
                if (field) {
                    field.sortOrder = index;
                    field.sortDirection = direction;
                } else {
                    options.fields!.push({
                        name: key,
                        sortOrder: index,
                        sortDirection: direction
                    });
                }
            });
        });

        if (this._additionalFields) {
            this._additionalFields.forEach(field => {
                if (options.fields!.findIndex(x => x.name === field) === -1) {
                    options.fields!.push({ name: field });
                }
            });
        }

        const data = await this._dataObject.dataHandler.retrieve(options);

        const root: NodeItem<T>[] = [];
        const keyMap: Record<string, NodeItem<T>> = {};
        const parentKeyMap: Record<string, string | undefined> = {};
        const populateParentRelations = (pKeyArr: string[]) => {
            if (pKeyArr.length === 1) {
                parentKeyMap[pKeyArr[0]] = undefined;
            } else {
                parentKeyMap[pKeyArr.at(-1)!] = pKeyArr.at(-2);
                populateParentRelations(pKeyArr.slice(0, pKeyArr.length - 1))
            }
        };

        const getKeyFromRecord = (item: any) => {
            if (item == null) { return undefined; }
            if (this._idPathField) {
                const idPath = `${item[this._idPathField]}`;
                if (!idPath) { return undefined; }
                const keyArray = this._parseKeyArray(item);
                if (this._getSummaryItem) {
                    populateParentRelations(keyArray);
                }
                return keyArray.join('/');
                return keyArray.at(-1);
            } else {
                const key = item[this._idField];
                return !key ? undefined : `${key}`;
            }
        };
        const getParentKeyFromRecord = (item: any) => {
            if (item == null) { return undefined; }
            if (this._idPathField) {
                const idPath = `${item[this._idPathField]}`;
                if (!idPath) { return undefined; }
                const keyArray = this._parseKeyArray(item);
                if (keyArray.length === 1) { return undefined; }
                return keyArray.slice(0, keyArray.length - 1).join('/');
                return keyArray.at(-2);
            } else {
                const parentKey = item[this._parentField];
                return !parentKey ? undefined : `${parentKey}`;
            }
        };

        const NodeItemModel = getNodeItemModel<T>({
            prefix: `${this._dataObject.id}`,
            passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
            calculatedFields: this._dataObject.nodeData.calculatedFields
        });

        const constructNodeFromStorage = (key: string) => {
            if (!key) { return undefined; }
            const item = this._structureItems.get(key);
            if (item == null) {
                if (this._getSummaryItem) {
                    const node = new NodeItemModel({
                        key: [key],
                        getConfiguration: () => this,
                        getNodeData: () => this._dataObject.nodeData,
                        // passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
                        getSummaryValues: async (pDetails) => {
                            const promises = pDetails.map(node => {
                                return new Promise<NodeItem<T>>(async (res) => {
                                    if (node.isLoading && node.loadingPromise) {
                                        await node.loadingPromise;
                                    }
                                    res(node);
                                });
                            });
                            const details = await Promise.all(promises);
                            return this._getSummaryItem!(details);
                        },
                    });
                    // node.updateItemPassthrough();
                    keyMap[`${key}`] = node;
                    return node;
                } else {
                    return undefined;
                }
            }
            const node = new NodeItemModel({
                key: [key],
                // primKey: item.PrimKey,
                fetchKey: item[this.keyField],
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                refreshItem: (pKey: string | number) => this.refreshItemByKeyField(pKey),
                // refreshItem: (primKey: string) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                //     appendRowCount: false,
                //     returnExisting: true
                // }),
                // passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
                partialItem: item as any
            });
            // node.updateItemPassthrough();
            keyMap[`${key}`] = node;

            return node;
        };

        // Construct node items for each record
        data.forEach(item => {
            const key = getKeyFromRecord(item);
            if (key == null) { return; }
            this._structureItems.set(key, item);
            // const parentKey = getParentKeyFromRecord(item);

            const node = new NodeItemModel({
                key: [key],
                fetchKey: item[this.keyField],
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                refreshItem: (pKey: string | number) => this.refreshItemByKeyField(pKey),
                partialItem: item,
                // refreshItem: (primKey: string) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                //     appendRowCount: false,
                //     returnExisting: true
                // }),
                // passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
            });
            // node.updateItemPassthrough();

            keyMap[`${key}`] = node;
        });

        const checkedKeys: Set<string> = new Set();
        const traverseToRoot = (key: string) => {
            if (checkedKeys.has(key)) { return; }
            checkedKeys.add(key);
            let parentKey: string | undefined = undefined;
            const item = this._structureItems.get(key);
            if (item == null || keyMap[key] == null) {
                if (this._getSummaryItem) {
                    parentKey = parentKeyMap[key];
                } else {
                    return;
                }
            } else {
                parentKey = getParentKeyFromRecord(item);
            }
            if (parentKey) {
                if (keyMap[parentKey]) {
                    const parentNode = keyMap[parentKey];
                    parentNode.details.push(keyMap[key]);
                    keyMap[key].getParent = () => parentNode;
                } else {
                    if (!this._requireParents) {
                        root.push(keyMap[key]);
                        return;
                    }
                    const parentNode = constructNodeFromStorage(parentKey);
                    if (parentNode) {
                        parentNode.details.push(keyMap[key]);
                        keyMap[key].getParent = () => parentNode;
                    } else {
                        if (this._requireParents) {
                            logger.warn(`${this._dataObject.id}: Could not assign node to a parent, missing parent item:`, item)
                            root.push(keyMap[key]);
                        } else {
                            root.push(keyMap[key]);
                        }
                    }
                }
                traverseToRoot(parentKey);
            } else {
                root.push(keyMap[key]);
            }
        };

        data.forEach(item => {
            const key = getKeyFromRecord(item);
            if (key == null) { return; }
            traverseToRoot(key);
        });

        if (this._aggregateSummaryNodes) {
            const traverseAggregate = (node: NodeItem<T>, aggregateArr: NodeItem<T>[] = []) => {
                if (node.isSummaryItem) { aggregateArr.push(node); }
                if (!node.isSummaryItem || node.details.length !== 1) {
                    node.details.forEach(detail => traverseAggregate(detail));
                } else {
                    node.details.forEach(detail => traverseAggregate(detail, aggregateArr));
                }

                if (aggregateArr.length > 1) {
                    const aggregatedKey = this._aggregateSummaryNodes!(aggregateArr);
                    const rootNode = aggregateArr[0];
                    const lastNode = aggregateArr.at(-1)!;
                    const parentNode = rootNode.getParent();
                    let rootIndex: number | null = null;

                    if (parentNode == null) {
                        rootIndex = root.findIndex(x => x.key === rootNode.key);
                    } else {
                        rootIndex = parentNode.details.findIndex(x => x.key === rootNode.key);
                    }
                    if (rootIndex === -1) { return; }

                    const aggregatedNode = new NodeItemModel({
                        key: [aggregatedKey],
                        getConfiguration: () => this,
                        getNodeData: () => this._dataObject.nodeData,
                        // passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
                        getSummaryValues: async (pDetails) => {
                            const promises = pDetails.map(node => {
                                return new Promise<NodeItem<T>>(async (res) => {
                                    if (node.isLoading && node.loadingPromise) {
                                        await node.loadingPromise;
                                    }
                                    res(node);
                                });
                            });
                            const details = await Promise.all(promises);
                            return this._getSummaryItem!(details);
                        },
                    });
                    // aggregatedNode.updateItemPassthrough();
                    if (parentNode) {
                        aggregatedNode.getParent = () => parentNode;

                    }
                    lastNode.details.forEach(detail => {
                        detail.getParent = () => aggregatedNode;
                        aggregatedNode.details.push(detail);
                    });

                    if (parentNode) {
                        parentNode.details.splice(rootIndex, 1, aggregatedNode);
                    } else {
                        root.splice(rootIndex, 1, aggregatedNode);
                    }

                }
            };

            root.forEach(node => traverseAggregate(node));
        }

        let deepestLevel = 0;
        // Final loop for updating levels and other properties that depend on the parent chain
        const updateLevels = (node: NodeItem<T>, level = 0, parentKey: string[] = []) => {
            if (level > deepestLevel) { deepestLevel = level; }
            node.level = level + parentLevel;
            for (let i = parentKey.length - 1; i >= 0; i--) {
                node.keyArray.unshift(parentKey[i]);
            }
            if ((pOptions.expandedKeys && pOptions.expandedKeys[node.key]) || (
                node.hasNodes && options.filterString && pOptions.autoExpandOnFilter
            )) {
                node.expanded = true;
            }
            node.details.forEach(detail => updateLevels(detail, level + 1, node.keyArray));
            sortData(options, node.details, this._dataObject.fields)

        };
        sortData(options, root, this._dataObject.fields)
        root.forEach(node => updateLevels(node));
        this._dataObject.nodeData.deepestLevel = deepestLevel;
        if (this._dataObject.nodeData.currentLevel > deepestLevel) {
            this._dataObject.nodeData.currentLevel = deepestLevel;
        }
        return root;
    }

    createNode(pOptions: NewNodeOptions<T>, pSkipReset = false) {
        let item = pOptions.item
        if (!(item instanceof DataItem)) {
            item = this._dataObject.createNew(pOptions.item, false);
            if (!pSkipReset) {
                item.reset();
            }
            this._dataObject.setCurrentIndex(item.index);
        }
        const node: NodeItem<T> = new NodeItem<T>({
            fetchKey: item[this.keyField],
            item: item as DataItemModel<T>,
            getNodeData: () => this._dataObject.nodeData,
            getConfiguration: () => this,
            refreshItem: (pKey: string | number) => this.refreshItemByKeyField(pKey),
            // refreshItem: (primKey: string) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
            //     appendRowCount: false,
            //     returnExisting: true
            // }),
            passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
        });
        node.updateItemPassthrough();
        // TODO(Augustas): Need better way to add accessor node to item
        (item as any)['_getNode'] = () => node;

        return node;
    }

    canCreateNodes(pNode: NodeItem<T>) {
        let developerCondition = true;
        if (this._canCreateNodes) {
            developerCondition = this._canCreateNodes(pNode);
        }
        return this._dataObject.allowInsert && developerCondition &&
            (this._bindToParent != null || (this._parentField != null && this._idField != null));
    }

    updateNodeParent(pNode: NodeItem<T>, pParent?: NodeItem<T>, pParentId?: string | number) {
        if (pNode.isLoading) { return; }

        if (this._bindToParent != null) {
            this._bindToParent(pNode, pParent);
        } else if (this._parentField != null && this._idField != null) {
            const parentId = pParent?.dataItem?.[this._idField] ?? pParentId ?? null;
            if (pNode.dataItem) {

                (pNode.dataItem as any)[this._parentField] = parentId;
            }
        }
        const updateKeysAndLevels = (node: NodeItem<T>, parent?: NodeItem<T>) => {
            node.level = parent ? parent.level + 1 : 0;
            const key = node.keyArray.at(-1)!;
            node.keyArray.splice(0, node.keyArray.length);
            if (parent) {
                node.keyArray.push(...parent.keyArray, key);
            } else {
                node.keyArray.push(key);
            }
            node.details.forEach(detail => {
                updateKeysAndLevels(detail, node);
            });
        };
        updateKeysAndLevels(pNode, pParent);

        // pNode.dataItem?.reset();
    }

    findNodeById(pId: string | number) {
        const traverseFind = (nodeArray: NodeItem<T>[]): NodeItem<T> | null => {
            for (const node of nodeArray) {
                const id = node.key.split('/').at(-1);
                if (id == pId) {
                    return node;
                }

                if (node.hasNodes) {
                    const foundNode: NodeItem<T> | null = traverseFind(node.details);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        };
        return traverseFind(this._dataObject.nodeData.root);
    }

    getRequiredFields() {
        let fields: string[] = [];
        const push = (pValue?: string) => { if (pValue) { fields.push(pValue); } }
        push(this._idField);
        push(this._parentField);
        push(this._idPathField);
        if (this._additionalFields) {
            fields = [...fields, ...this._additionalFields];
        }
        return fields;
    }

    // getPlaceholder: (pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) => NodeItem<T>;
    /** Client side hierarchy */
    getNodes(pData: Partial<T>[], pOptions: NodeDataStructureOptions) {
        const root: NodeItem<T>[] = [];
        const lookup: Record<string | number, NodeItem<T>> = {};

        const sortOrder = this._dataObject.fields.combinedFields
            .filter((field) => field.sortDirection && field.sortOrder != null)
            .sort((a, b) => { return a.sortOrder! - b.sortOrder! })
            .map((item) => item.item);
        
        const passthroughKeys = new Set<string>(); 
        for (const field of this._dataObject.fields.fields) {
            passthroughKeys.add(field.name);
        }
        for (const sortField of sortOrder) {
            passthroughKeys.add(sortField.name);
        }

        const NodeItemModel = getNodeItemModel<T>({
            prefix: `${this._dataObject.id}`,
            passthroughKeys: Array.from(passthroughKeys),
            calculatedFields: this._dataObject.nodeData.calculatedFields
        })

        for (const item of pData) {
            const id = item[this.idField as any] as string | number;

            const node = new NodeItemModel({
                key: [id],
                fetchKey: item[this.keyField],
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                refreshItem: (pKey: string | number) => this.refreshItemByKeyField(pKey),
                partialItem: item,
                // passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
            });
            // node.updateItemPassthrough();

            if ((pOptions.expandedKeys && pOptions.expandedKeys[node.key]) || (
                node.hasNodes && pOptions.autoExpandOnFilter && this._dataObject.recordSource.filterString
            )) {
                node.expanded = true;
            }
            lookup[id] = node;
        }

        for (const item of pData) {
            const id = item[this.idField as any];
            const parentId = item[this.parentField as any];
            const node = lookup[id as any];
            if (parentId && lookup[parentId]) {
                const parentNode = lookup[parentId];
                parentNode.details.push(node);
                node.getParent = () => parentNode;
            } else {
                root.push(node)
            }
        }
        let maxDepth = pOptions.startingLevel ?? 0;
        const traverse = (pNodes: NodeItem<T>[], pDepth: number) => {
            sortData({ fields: sortOrder ?? [] }, pNodes, this._dataObject.fields)
            if (pNodes.length && maxDepth < pDepth) {
                maxDepth = pDepth;
            }
            for (const node of pNodes) {
                node.level = pDepth;
                traverse(node.details, pDepth + 1);
            }
        };
        traverse(root, pOptions.startingLevel ?? 0);

        return {
            root: root,
            boundry: [],
            depth: maxDepth
        };
    }

    getPlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        if (pNode.getFilterString == null) { throw new Error(`Can't load placeholder with no filter string`); }
        const placeholderNode: NodeItem<T> = new NodeItem<T>({
            key: [...pNode.keyArray, '<PLACEHOLDER>'],
            getNodeData: () => this._dataObject.nodeData,
            getFilterString: pNode.getFilterString,
            beforeLoad: (pNode) => this._replacePlaceholder(pNode, pOptions),
            getConfiguration: () => this,
            passthroughKeys: []
        });
        placeholderNode.level = pNode.level + 1;
        return placeholderNode;
    }

    private async _replacePlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        const parentNode = pNode.getParent();
        if (parentNode == null) { throw new Error(`Placeholder item does not have a parent`); }
        const nodes = await this.getStructure(pOptions, parentNode);
        parentNode.details.splice(0, parentNode.details.length);
        nodes.forEach(node => {
            node.getParent = () => parentNode;
            parentNode.details.push(node);
        });
        this._dataObject.nodeData.update();
    }
}

export function isHierarchyLevelConfiguration<T extends ItemModel = ItemModel>(pConfiguration: INodeDataLevelConfiguration<T>): pConfiguration is HierarchyLevelConfiguration<T> {
    return pConfiguration.type == 'hierarchy';
}

export type NodeDataHierarchyConfigurationOptions<T extends ItemModel = ItemModel> = {
    type: 'hierarchy'
    idField?: string;
    parentField?: string;
    idPathField?: string;
    /**
     * When set to true nodes that have parents will reuqire the parent records to be present in the where clause. This will also 
     * move the context filter from where clause to filter string.
     * Otherwise nodes that are missing parents in the current where clause will be treated as root elements
     */
    requireParents?: boolean;
    /**
     * Additional fields to retreive when getting the structure
     */
    additionalFields?: string[];
    /**
     * Function used when adding new items in the node structure. Should update `pItem` with values from 
     * the `pParent`  
     * @param pItem Current node being updated
     * @param pParent Parent node of the pItem
     */
    bindToParent?: (pItem: NodeItem<T>, pParent?: NodeItem<T>) => void;
    /**
     * Optional function for generating a unique path array from retrieved structure configuration options
     * For example this is can be used to create folder/hierarchy structures based on a single string field
     */
    parseKeyArray?: (pitem: Partial<T>) => string[]
    /**
     * Optional function for creating summary rows for nodes missing parent data. 
     * If this function is not provided nodes with missing parents in the structure will be excluded.
     */
    getSummaryItem?: (pDetails: NodeItem<T>[]) => T;
    /**
     * Optional function for aggreating summary rows that have only detail summary item.
     * For example instead of having single child rows like 'Summary Row 1' -> 'Summary Row 2' -> 'Data Row' you can combine them into
     * 'Summary Row 1/Summary Row 2' -> 'Data Row'
     */
    aggregateSummaryNodes?: (pDetails: NodeItem<T>[]) => string;
    /** Developer set condition if a node can instert new nodes */
    canCreateNodes?: (pNode: NodeItem<T>) => boolean;
    expandByDefault?: boolean;
}
